import React, { FunctionComponent, PropsWithChildren, useContext } from 'react'
import { v4 as uuid } from 'uuid'
import {
  WizardAnswersSelector,
  WizardDataStructureSelector,
  WizardLocationsSelector,
  WizardModeSelector,
  WizardNumberingSelector,
  WizardQuestionsSelector,
  WizardIntegrationsSelector,
  WizardActiveSplitSelector,
  WizardState,
  WizardInstancingSelector,
  WizardQuestionLayoutSelector,
} from '___store'
import { evaluateMarkers, initializeAnswerRelevance } from '___store/storeSegments/wizard/typified/helpers'

import {
  ANSWER_VALUE_MATCH,
  CASUS_IDS,
  DataStructure,
  EditorMode,
  LANGUAGES,
  LanguageValue,
  Languages,
  Locations,
  NESTED_STRUCTURE_KEYS,
  OptionValueTypeUnionType,
  ParagraphObject,
  SEGMENT_TYPES,
  SegmentsLocation,
  TextChunkObject,
  TextChunks,
  TextLocation,
  VALUE_SOURCE_TYPE_MATCH,
} from '___types'
import Interact from './Interact'
import Preview from './Preview'

import './style.scss'
import { calculate, parseCalculation } from '___utilities'
import { formatMarkerValue, getFormattedOptionValue } from 'utilities/helpers'
import { SEGMENT_TAGS } from 'utilities/parsing'

// import { mergeChunks } from 'Wizard/parsing'

// ======================================================================================================= //
// ============================================ TABLE CONTEXT ============================================ //
// ======================================================================================================= //
type TableContextType = { id: string }
export const TableContext = React.createContext<TableContextType>({} as TableContextType)
type TableContextProviderProps = PropsWithChildren<TableContextType>
export const TableContextProvider: FunctionComponent<TableContextProviderProps> = ({ id, children }) => {
  return <TableContext.Provider value={{ id }}>{children}</TableContext.Provider>
}
export const useTableContext = () => {
  const currentTableContext = useContext(TableContext)
  if (!currentTableContext) {
    throw new Error('useTableContext has to be used within <TableContext.Provider>')
  }
  return currentTableContext
}
// ======================================================================================================= //
//
//
//
// ======================================================================================================= //
// =========================================== SECTION CONTEXT =========================================== //
// ======================================================================================================= //
type SectionContextType = { index: number }
export const SectionContext = React.createContext<SectionContextType>({} as SectionContextType)
type SectionContextProviderProps = PropsWithChildren<SectionContextType>
export const SectionContextProvider: FunctionComponent<SectionContextProviderProps> = ({ index, children }) => {
  return <SectionContext.Provider value={{ index }}>{children}</SectionContext.Provider>
}
export const useSectionContext = () => {
  const currentSectionContext = useContext(SectionContext)
  if (!currentSectionContext) {
    throw new Error('useSectionContext has to be used within <SectionContext.Provider>')
  }
  return currentSectionContext
}
// ======================================================================================================= //
//
//
//
// ======================================================================================================= //
// ======================================= EDITOR INTERACT CONTEXT ======================================= //
// ======================================================================================================= //
type EditorContextType = {
  mode: EditorMode
  dataStructure?: WizardDataStructureSelector
  numbering?: WizardNumberingSelector
  locations?: WizardLocationsSelector
}
export const EditorContext = React.createContext<EditorContextType>({} as EditorContextType)
type EditorContextProviderProps = PropsWithChildren<EditorContextType>
export const EditorContextProvider: FunctionComponent<EditorContextProviderProps> = ({ mode, dataStructure, numbering, locations, children }) => {
  return <EditorContext.Provider value={{ mode, dataStructure, numbering, locations }}>{children}</EditorContext.Provider>
}
export const useEditorContext = () => {
  const currentEditorContext = useContext(EditorContext)
  if (!currentEditorContext) {
    throw new Error('useEditorContext has to be used within <EditorContext.Provider>')
  }
  return currentEditorContext
}
// ======================================================================================================= //
//
//
//
// ======================================================================================================== //
// ======================================== EDITOR PREVIEW CONTEXT ======================================== //
// ======================================================================================================== //
type EditorPreviewContextType = {
  mode: EditorMode
  dataStructure?: WizardDataStructureSelector
  numbering?: WizardNumberingSelector
}
export const EditorPreviewContext = React.createContext<EditorPreviewContextType>({} as EditorPreviewContextType)
type EditorPreviewContextProviderProps = PropsWithChildren<EditorPreviewContextType>
export const EditorPreviewContextProvider: FunctionComponent<EditorPreviewContextProviderProps> = ({ mode, dataStructure, numbering, children }) => {
  return <EditorPreviewContext.Provider value={{ mode, dataStructure, numbering }}>{children}</EditorPreviewContext.Provider>
}
export const useEditorPreviewContext = () => {
  const currentEditorPreviewContext = useContext(EditorPreviewContext)
  if (!currentEditorPreviewContext) {
    throw new Error('useEditorPreviewContext has to be used within <EditorPreviewContext.Provider>')
  }
  return currentEditorPreviewContext
}
// ======================================================================================================== //

export { Interact, Preview }

// const findIndices = <T extends 'text' | 'content'>(
//   array: { [K in T]: unknown[] | string }[],
//   key: T,
//   range: [number, number],
//   buffer = 0,
//   startIndex = -1,
//   endIndex = -1
// ): [number, number] | undefined =>
//   array.find((entry, i) => {
//     const start = buffer
//     const end = buffer + entry[key].length
//     if (range[0] >= start && range[0] <= end) startIndex = i
//     return (buffer = end) && range[1] >= start && range[1] <= end && ((endIndex = i) || true)
//   }) && [startIndex, endIndex]

type TextMarkerObject = {
  type: 'marker'
  id: string
  offset: number
  instance: number
  context: Record<string, number>
  content?: TextChunkObject[]
  textChunks?: TextChunkObject[]
  text: string
}

type StructureObject = Record<string, unknown> & {
  id?: string
  customStyle?: string
  styles?: string[]
  textChunks?: TextChunks
  type?: 'marker'
  offset?: number
  instance?: number
  context?: Record<string, number>
  content?: StructureObject[]
}

const rasterizeMarkers = (structure: StructureObject): StructureObject | StructureObject[] => {
  if (structure.type === 'marker') return (structure.content as StructureObject[])?.map(structure => rasterizeMarkers(structure)).flat()
  return NESTED_STRUCTURE_KEYS.reduce((result, key) => {
    if (Array.isArray(result[key])) Object.assign(result, { [key]: (result[key] as StructureObject[]).map(rasterizeMarkers).flat() })
    return result
  }, structure)
}

const applyTextMarkers = (
  structure: StructureObject,
  textMarkers: TextLocation[],
  questionParents: Record<string, string>,
  optionParents: Record<string, string>
) => {
  const { content, textChunks } = structure
  if (!textMarkers.length || !textChunks?.length) return structure

  const { offset, context } = structure

  const resultingTextChunks = textMarkers.reduce((result, marker) => {
    const { id, range, instancing, instanceCount, defaultKeep, keep, replace, optionIds, valueSources, combine, calculation, formatting } = marker
    const start = range[0] - (offset ?? 0)
    const end = range[1] - (offset ?? 0)

    const [[leading, markerChunks, trailing]] = result.reduce(
      ([result, accumulated], chunk) => {
        const relativeStart = start - accumulated
        const relativeEnd = end - accumulated
        const { text } = chunk
        if (relativeStart >= text.length) result[0].push(chunk)
        else if (relativeEnd <= 0) result[2].push(chunk)
        else {
          if (relativeStart > 0 && relativeStart < text.length) {
            const leading = Object.assign({}, chunk, { text: text.slice(0, relativeStart) })
            result[0].push(leading)
          }
          if (relativeEnd < text.length && relativeEnd > 0) {
            const trailing = Object.assign({}, chunk, { text: text.slice(relativeEnd) })
            result[2].push(trailing)
          }
          const insert = Object.assign({}, chunk, {
            text: text.slice(Math.max(relativeStart, 0), Math.min(relativeEnd, text.length)),
          })
          result[1].push(insert)
        }

        return [result, accumulated + text.length] as [TextChunkObject[][], number]
      },
      [[[], [], []], 0] as [(TextChunkObject | TextMarkerObject)[][], number]
    )

    const markerResult = new Array(instancing ? instanceCount : 1).fill(markerChunks).map((textChunks: TextChunkObject[], i) => {
      const resultingContext = Object.assign({}, context, instancing ? { [instancing]: i } : undefined)
      const resultingMarker = {
        type: 'marker',
        id,
        offset: range[0],
        instance: i,
        context: resultingContext,
        textChunks,
        content: JSON.parse(JSON.stringify(textChunks)),
        text: textChunks.reduce((result, { text }) => result + text, ''),
      } as TextMarkerObject

      // ======================= //
      // EVALUATE MARKER REPLACE //
      // ======================= //
      const valueSourceRelevantInstances = Object.entries(replace || {}).reduce((result, [valueSource]) => {
        let relevantInstance = 0
        const { id, instance } = valueSource.match(VALUE_SOURCE_TYPE_MATCH)?.groups || {}
        const questionGroupId = questionParents[id]
        if (instance === 'parent-instance' && questionGroupId) relevantInstance = resultingContext[questionGroupId] ?? 0
        // HANDLE OTHER INSTANCING CASES
        return Object.assign(result, { [valueSource]: String(relevantInstance) })
      }, {} as Record<string, string>)

      const replaceValues = [] as string[]

      if (combine) {
        const parsedCalculation = parseCalculation(calculation || '')
        const references = valueSources.map(
          source => replace?.[source][valueSourceRelevantInstances[source]]?.map(({ value }) => Number(value)) || []
        )
        replaceValues.push(...calculate(parsedCalculation, references).map(value => String(value)))
      } else
        replaceValues.push(
          ...Object.entries(replace || {}).reduce(
            (result, [valueSource, instanceValues]) =>
              result.concat(
                (instanceValues[valueSourceRelevantInstances[valueSource]] || []).map(({ type, value }) =>
                  String(getFormattedOptionValue(type as OptionValueTypeUnionType, value))
                )
              ),
            [] as string[]
          )
        )

      if (replaceValues.length) {
        const resultingChunk = generateTextChunk({
          text: replaceValues.reduce((result, value) => result + (formatting ? formatMarkerValue(value, formatting) : value), ''),
          styles: resultingMarker.textChunks?.[0].styles,
          customStyle: resultingMarker.textChunks?.[0].customStyle,
        })
        Object.assign(resultingMarker, { content: [resultingChunk] })
      }
      // ======================= //

      // ============================ //
      // EVALUATE MARKER KEEP/DISCARD //
      // ============================ //
      if (typeof keep === 'boolean') {
        if (!keep) Object.assign(resultingMarker, { content: [] })
      } else {
        const relevantKeepValues = (keep as string[])?.reduce((result, value) => {
          const { source, instanceIndex } = value.match(ANSWER_VALUE_MATCH)?.groups || {}
          const relevantOptionConnection = optionIds?.find(optionIdString => {
            const [id, instance] = optionIdString.split(':')
            if (id !== source) return false
            return instance === 'parent-instance' ? String(resultingContext[optionParents[id]] ?? 0) === instanceIndex : instance === instanceIndex
            // HANDLE OTHER INSTANCING CASES
          })
          return result.concat(relevantOptionConnection ? value : [])
        }, [] as string[])
        if (defaultKeep ? !relevantKeepValues.length : optionIds?.length !== relevantKeepValues.length)
          Object.assign(resultingMarker, { content: [] })
      }
      // ============================ //

      return resultingMarker
    })

    return ([] as (TextChunkObject | TextMarkerObject)[]).concat(leading, markerResult, trailing)
  }, (content ?? textChunks) as (TextChunkObject | TextMarkerObject)[])

  Object.assign(structure, { textChunks: resultingTextChunks })

  if (structure.type === 'marker') Object.assign(structure, { content: resultingTextChunks })
}

const genericTextChunk = { type: SEGMENT_TYPES.TEXT_CHUNK, tag: SEGMENT_TAGS[SEGMENT_TYPES.TEXT_CHUNK], customStyle: '', styles: [], text: '' }
const generateTextChunk = (chunk: Partial<TextChunkObject>) => Object.assign({}, genericTextChunk, { styles: [] }, chunk)
const genericParagraph = {
  type: SEGMENT_TYPES.PARAGRAPH,
  tag: SEGMENT_TAGS[SEGMENT_TYPES.PARAGRAPH],
  customStyle: 'Normal',
  styles: [],
  textChunks: [],
}
const generateParagraph = (paragraph: Partial<ParagraphObject>) =>
  Object.assign({}, genericParagraph, { id: uuid(), styles: [], textChunks: [] }, paragraph)

const applySegmentsMarkers = (
  structure: StructureObject,
  segmentsMarkers: SegmentsLocation[],
  questionParents: Record<string, string>,
  optionParents: Record<string, string>
) => {
  const relevantContentKey = 'segments' in structure ? 'segments' : 'content'
  const structureContent = structure[relevantContentKey] as StructureObject[]
  if (!structureContent?.length) return
  const { offset, context } = structure
  Object.assign(structure, {
    [relevantContentKey]: segmentsMarkers
      .reduce((result, marker) => {
        const {
          id,
          range,
          instancing,
          instanceCount,
          defaultKeep,
          keep,
          replace,
          optionIds,
          valueSources,
          combine,
          calculation,
          formatting,
          contentStyles,
          contentCustomStyle,
        } = marker
        const start = range[0] - (offset ?? 0)
        const span = range[1] - range[0]
        const resultingItems = new Array(span).fill(null) as StructureObject[][]
        const relevantContent = structureContent.slice(start, start + span)
        const markerResult = new Array(instancing ? instanceCount : 1).fill(relevantContent).map((content: StructureObject[], i) => {
          const resultingContext = Object.assign({}, context, instancing ? { [instancing]: i } : undefined)
          const resultingMarker = {
            type: 'marker',
            id,
            offset: range[0],
            instance: i,
            context: resultingContext,
            content: content.map(entry => (entry.id ? Object.assign({}, JSON.parse(JSON.stringify(entry)), { id: `${entry.id}:${i}` }) : entry)),
          } as StructureObject

          // ============================ //
          // EVALUATE MARKER KEEP/DISCARD //
          // ============================ //
          if (typeof keep === 'boolean') {
            if (!keep) Object.assign(resultingMarker, { content: [] })
          } else {
            const relevantKeepValues = (keep as string[])?.reduce((result, value) => {
              const { source, instanceIndex } = value.match(ANSWER_VALUE_MATCH)?.groups || {}
              const relevantOptionConnection = optionIds?.find(optionIdString => {
                const [id, instance] = optionIdString.split(':')
                if (id !== source) return false
                return instance === 'parent-instance'
                  ? String(resultingContext[optionParents[id]] ?? 0) === instanceIndex
                  : instance === instanceIndex
                // HANDLE OTHER INSTANCING CASES
              })
              return result.concat(relevantOptionConnection ? value : [])
            }, [] as string[])
            if (defaultKeep ? !relevantKeepValues.length : optionIds?.length !== relevantKeepValues.length)
              Object.assign(resultingMarker, { content: [] })
          }
          // ============================ //

          // ======================= //
          // EVALUATE MARKER REPLACE //
          // ======================= //
          const valueSourceRelevantInstances = Object.entries(replace || {}).reduce((result, [valueSource]) => {
            let relevantInstance = 0
            const { id, instance } = valueSource.match(VALUE_SOURCE_TYPE_MATCH)?.groups || {}
            const questionGroupId = questionParents[id]
            if (instance === 'parent-instance' && questionGroupId) relevantInstance = resultingContext[questionGroupId] ?? 0
            // HANDLE OTHER INSTANCING CASES
            return Object.assign(result, { [valueSource]: String(relevantInstance) })
          }, {} as Record<string, string>)

          const replaceValues = [] as string[]

          if (combine) {
            const parsedCalculation = parseCalculation(calculation || '')
            const references = valueSources.map(
              source => replace?.[source][valueSourceRelevantInstances[source]]?.map(({ value }) => Number(value)) || []
            )
            replaceValues.push(...calculate(parsedCalculation, references).map(value => String(value)))
          } else
            replaceValues.push(
              ...Object.entries(replace || {}).reduce(
                (result, [valueSource, instanceValues]) =>
                  result.concat(
                    (instanceValues[valueSourceRelevantInstances[valueSource]] || []).map(({ type, value }) =>
                      String(getFormattedOptionValue(type as OptionValueTypeUnionType, value))
                    )
                  ),
                [] as string[]
              )
            )
          // ======================= //

          if (replaceValues.length)
            Object.assign(resultingMarker, {
              content: replaceValues.map(value => {
                const replaceParagraph = {
                  textChunks: [
                    generateTextChunk({
                      text: formatting ? formatMarkerValue(value, formatting) : value,
                      styles: contentStyles,
                      customStyle: contentCustomStyle,
                    }),
                  ],
                  styles: contentStyles,
                  customStyle: contentCustomStyle,
                }
                return generateParagraph(replaceParagraph)
              }),
            })

          return resultingMarker
        })
        resultingItems[0] = markerResult
        result.splice(start, span, ...resultingItems.map(item => item ?? []))
        return result
      }, structureContent as (StructureObject | StructureObject[])[])
      .map(structure => (Array.isArray(structure) || structure.type !== 'marker' ? structure : structure.content))
      .flat(),
  })
}

const applyAnswerValuesToDataStructure = (
  structure: StructureObject,
  locations: Locations,
  questionParents: Record<string, string>,
  optionParents: Record<string, string>
): [StructureObject, boolean] => {
  const id = structure.id === CASUS_IDS.DATASTRUCTURE_ID ? 'root' : String(structure.id).split(':')[0]
  const segmentsMarkers = locations.segments[id!] ?? []
  const textMarkers = locations.text[id!] ?? []

  if (segmentsMarkers.length) applySegmentsMarkers(structure, segmentsMarkers, questionParents, optionParents)

  if (textMarkers.length) applyTextMarkers(structure, textMarkers, questionParents, optionParents)

  let changed = false
  const result = NESTED_STRUCTURE_KEYS.reduce(
    (accumulated, key) =>
      Array.isArray(accumulated[key]) && (accumulated[key] as StructureObject[]).length
        ? Object.assign(accumulated, {
            [key]: (accumulated[key] as StructureObject[]).map(structure => {
              const [nestedStructure, nestedChanged] = applyAnswerValuesToDataStructure(structure, locations, questionParents, optionParents)
              changed = changed || nestedChanged
              return nestedStructure
            }),
          })
        : accumulated,
    structure
  )
  return [result, Boolean(textMarkers.length || segmentsMarkers.length) || changed]
}

export const rasterizeDataStructure = (
  mode: WizardModeSelector,
  dataStructure: DataStructure,
  locations: WizardLocationsSelector,
  questions: WizardQuestionsSelector,
  questionLayout: WizardQuestionLayoutSelector,
  selectedLanguages?: LanguageValue[],
  integrations?: WizardIntegrationsSelector,
  activeSplit?: WizardActiveSplitSelector,
  instancing?: WizardInstancingSelector,
  answers?: WizardAnswersSelector
): [DataStructure, StructureObject[], StructureObject[]] => {
  if (!locations) return [dataStructure, [], []]

  const languages = { available: Object.values(LANGUAGES), select: 'multi', selected: selectedLanguages } as Languages
  const pseudoState = { mode, dataStructure, locations, questions, questionLayout, languages, integrations, activeSplit, instancing, answers }
  const evaluatedMarkers = evaluateMarkers(initializeAnswerRelevance(pseudoState as WizardState), false).locations

  const questionParents = (questionLayout || []).reduce(
    (result, layoutGroup) => layoutGroup.questions.reduce((result, questionId) => Object.assign(result, { [questionId]: layoutGroup.id }), result),
    {} as Record<string, string>
  )
  const optionParents =
    questions?.reduce((result, { id, optionGroups }) => {
      const payload = optionGroups.reduce((result, { options }) => {
        const payload = options.reduce((result, { id: optionId }) => Object.assign(result, { [optionId]: questionParents[id] }), {})
        return Object.assign(result, payload)
      }, {})
      return Object.assign(result, payload)
    }, {}) || {}

  const answeredDataStructure = applyAnswerValuesToDataStructure(
    JSON.parse(JSON.stringify(dataStructure)),
    evaluatedMarkers!,
    questionParents,
    optionParents
  )[0] as DataStructure
  const rasterizedDataStructure = rasterizeMarkers(answeredDataStructure) as DataStructure
  const changedHeaders = dataStructure.headers.reduce((headers, header) => {
    const [result, changed] = applyAnswerValuesToDataStructure(JSON.parse(JSON.stringify(header)), evaluatedMarkers!, questionParents, optionParents)
    return headers.concat(changed ? rasterizeMarkers(result) : [])
  }, [] as StructureObject[])
  const changedFooters = dataStructure.footers.reduce((footers, footer) => {
    const [result, changed] = applyAnswerValuesToDataStructure(JSON.parse(JSON.stringify(footer)), evaluatedMarkers!, questionParents, optionParents)
    return footers.concat(changed ? rasterizeMarkers(result) : [])
  }, [] as StructureObject[])
  return [rasterizedDataStructure, changedHeaders, changedFooters]
}
